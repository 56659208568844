import { defer } from 'q';
import axios from 'axios';
import getErrorData from '../ExceptionHandling/api.global.exception';
import { buildThunkHelperActionObject } from '../utilities/tools';
import handleJsError from '../ExceptionHandling/handle.js.error';

const handleApiThunkHelperException = (errorData, errorType, dispatch, useDefaultHandler) => {
  dispatch(
    buildThunkHelperActionObject(errorType, errorData),
  );

  if (useDefaultHandler) {
    // Dispatch error action for component's integration
    handleJsError(dispatch, errorData);
  }
};

const onApiThunkHelperFail = (exception,
  exceptionHandlerConfig,
  apiConfig,
  errorType,
  dispatch,
  deferred) => {
  const errorData = getErrorData(exception, exceptionHandlerConfig, apiConfig);
  handleApiThunkHelperException(
    errorData,
    errorType,
    dispatch,
    exceptionHandlerConfig.useDefaultHandler,
  );

  deferred.reject(errorData);
};

const apiThunkHelper = (
  dispatch,
  [requestType, successType, errorType],
  apiConfig,
  exceptionHandlerConfig,
) => {
  const deferred = defer();

  try {
    dispatch(
      buildThunkHelperActionObject(requestType),
    );

    axios(apiConfig)
      .then((response) => {
        dispatch(
          buildThunkHelperActionObject(successType, response.data),
        );
        deferred.resolve(response.data);
      })
      .catch((exception) => {
        onApiThunkHelperFail(
          exception,
          exceptionHandlerConfig,
          apiConfig,
          errorType,
          dispatch,
          deferred,
        );
      });
  } catch (error) {
    onApiThunkHelperFail(
      error,
      exceptionHandlerConfig,
      apiConfig,
      errorType,
      dispatch,
      deferred,
    );
  }

  return deferred.promise;
};

export default apiThunkHelper;
