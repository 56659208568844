import {
  merge, has, isFunction, isPlainObject, set,
} from 'lodash';
import { isDefinedAndNotNullAndNotEmpty } from '../utilities/tools';

const getEncodeValue = (key) => localStorage.getItem(window.btoa(key));

const setLocalStorage = (key, value) => {
  localStorage.setItem(window.btoa(key), window.btoa(value));
};

const isSetLocalStorage = (key) => isDefinedAndNotNullAndNotEmpty(getEncodeValue(key));

const getLocalStorage = (key) => {
  const value = getEncodeValue(key);

  if (isDefinedAndNotNullAndNotEmpty(value)) {
    try {
      return window.atob(value);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

const clearLocalStorage = () => {
  if (isFunction(localStorage.clear)) {
    localStorage.clear();
  }
};

const removeFromLocalStorage = (key) => {
  localStorage.removeItem(window.btoa(key));
};

const setObjectLocalStorage = (key, value) => {
  if (!isPlainObject(value)) {
    throw new Error('setObjectLocalStorage requires an object as value');
  }

  const currentValue = getLocalStorage(key);

  const obj = (isDefinedAndNotNullAndNotEmpty(currentValue))
    ? merge(JSON.parse(currentValue || '{}'), value)
    : value;

  setLocalStorage(key, JSON.stringify(obj));
};

const updateObjectLocalStorage = (key, path, value) => {
  if (!isSetLocalStorage(key)) return;

  const currentValue = JSON.parse(getLocalStorage(key));

  if (has(currentValue, path)) {
    set(currentValue, path, value);
    setLocalStorage(key, JSON.stringify(currentValue));
  }
};

const getObjectLocalStorage = (key) => {
  const value = getLocalStorage(key);

  if (isDefinedAndNotNullAndNotEmpty(value)) {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn('Error at getObjectLocalStorage', key, value);
      console.error(e);
      return undefined;
    }
  }
  return undefined;
};

export {
  clearLocalStorage,
  getLocalStorage,
  getObjectLocalStorage,
  isSetLocalStorage,
  removeFromLocalStorage,
  setLocalStorage,
  setObjectLocalStorage,
  updateObjectLocalStorage,
};
