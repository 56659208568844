import moment from 'moment';
import { LOCAL_DATE_TIME_FORMATS, DATE_FORMATS } from './constants';
import { isDefinedAndNotNullAndNotEmpty } from './tools';

const validateMoment = (momentDate) => {
  if (!momentDate.isValid()) {
    throw new Error('Invalid date value');
  }
};

const getMoment = (date) => {
  const newMoment = moment(date);
  validateMoment(newMoment);
  return newMoment;
};

const convertDateTimeToUtc = (dateToConvert,
  format = LOCAL_DATE_TIME_FORMATS.LOCAL_DATE_TIME) => moment
  .utc(getMoment(dateToConvert), format);

const convertDateToUtc = (dateToConvert, format = DATE_FORMATS.DATE) => moment
  .utc(getMoment(dateToConvert), format);

const convertUtcDateTimeToLocalDateTime = (utcDateTime) => {
  const utcMoment = moment.utc(utcDateTime);
  validateMoment(utcMoment);

  return moment(utcMoment
    .local()
    .format(LOCAL_DATE_TIME_FORMATS.LOCAL_DATE_TIME));
};

const convertUtcDateTimeToLocalDateTimeDate = convertUtcDateTimeToLocalDateTime().toDate();

const getUTCNow = () => convertDateToUtc(new Date());
const getUTCNowDate = () => getUTCNow().toDate();
const now = () => getMoment(new Date()).local();
const nowDate = () => now().toDate();

// Format a Local Date type into a string based on the formatTemplate provided.
// A default template is declared. A cultureCode must be provided to define Date's locale.
const formatLocalDateTimeCulture = (localDate, cultureCode, formatTemplate) => {
  const localMoment = getMoment(localDate);

  if (!isDefinedAndNotNullAndNotEmpty(cultureCode)) {
    throw new Error('cultureCode cannot be null');
  }

  if (!isDefinedAndNotNullAndNotEmpty(formatTemplate)) {
    throw new Error('formatTemplate cannot be null');
  }

  // Convert the Date to a local Date, applies a cultureCode to modify the locale,
  // and, finally, format Date based on the specified string
  return localMoment
    .locale(cultureCode)
    .format(formatTemplate);
};

export {
  convertDateTimeToUtc,
  convertDateToUtc,
  convertUtcDateTimeToLocalDateTime,
  convertUtcDateTimeToLocalDateTimeDate,
  formatLocalDateTimeCulture,
  getMoment,
  getUTCNow,
  getUTCNowDate,
  now,
  nowDate,
  validateMoment,
};
