import {
  reduce, isString, isPlainObject, isEqual, isDate, isNumber, isUndefined,
} from 'lodash';
import moment from 'moment';
import { LOCAL_DATE_TIME_FORMATS } from './constants';

const isValidNumber = (value) => {
  // Check if value is not defined as a Number primitive or an object.
  if (!isNumber(value)) {
    return false;
  }
  // Check if value is not defined as a Number type. If not, the method return as false.
  return !Number.isNaN(value);
};

const getEnvironmentShortcode = (environment) => {
  let shortcode;
  switch (environment.toString().toLowerCase()) {
    case 'production':
      shortcode = 'prod';
      break;
    case 'uat':
      shortcode = 'uat';
      break;
    case 'development':
      shortcode = 'dev';
      break;
    case 'develop':
      shortcode = 'dev';
      break;
    default:
      shortcode = environment.toString().toLowerCase();
      break;
  }
  return shortcode;
};

const isValidNumberGreaterThanZero = (value) => {
  // Check if value is defined as a Number primitive or an object.
  if (!isNumber(value)) {
    return false;
  }

  // Check if value is not defined as a Number type and then check if the parsed string is
  // greater than zero. If either fails, the method return as false.
  return !Number.isNaN(value) && Number.parseInt(value, 2) > 0;
};

const isDefinedAndNotNullAndNotEmpty = (value) => {
  // Checks to see if value is undefined, null, or contains a empty string.
  // If so, method returns false.
  if (isString(value)) {
    if (value.trim() === '') {
      return false;
    }
  }

  if (isUndefined(value)
      || value === null
      || value === '') {
    return false;
  }

  return true;
};

// This function formats a prefix to be in front of the
// visitorId like uat-123.
// Format is: <region (ie. us)>-<env short (ie. dev, uat, prod)>-<vistorID>
const formatPendoVistorId = (enviroment, userId, region) => {
  const enviromentFormatted = getEnvironmentShortcode(enviroment);
  let regionFormatted = '';

  if (isDefinedAndNotNullAndNotEmpty(region)) {
    regionFormatted = region.toLowerCase() === 'can' ? '' : `${region.toLowerCase()}-`;
  }

  return `${regionFormatted}${enviromentFormatted}-${userId}`;
};

const isPropertyDefinedAndNotNullAndNotEmpty = (obj, path) => {
  // Check if obj is not null, undefined, or an empty string or
  // if obj is not an Object that was created by an Object constructor or prototype.
  // If false, the method returns false.
  if (!isDefinedAndNotNullAndNotEmpty(obj) || !isPlainObject(obj)) {
    return false;
  }

  const args = path.split('.');
  let _obj = obj;
  let pivot;

  for (let i = 0; i < args.length; i++) {
    pivot = _obj[args[i]];

    if (Object.prototype.hasOwnProperty.call(_obj, args[i])
    && isDefinedAndNotNullAndNotEmpty(pivot)) {
      _obj = pivot;
    } else {
      return false;
    }
  }
  return true;
};

const areEqual = (objectOne, objectTwo) => {
  // Checks if objectOne or objectTwo is null, undefined, or an empty string.
  // If either fails the check, the method returns false.
  if (isDefinedAndNotNullAndNotEmpty(objectOne) || isDefinedAndNotNullAndNotEmpty(objectTwo)) {
    return false;
  }

  // Checks is the two objects match the object property's names and their values.
  return isEqual(objectOne, objectTwo);
};
// Compare two objects and find properties that have changed. After changes have been found,
// we return an array of the modified properties.
const getChangedProperties = (objectOne, objectTwo) => reduce(objectOne,
  (res, value, key) => (
    // Check if each property of objectOne equals it's corresponding property
    // (specified by key) in objectTwo. If true, array is not updated. if false, we add the property
    // to the array. An array with changed properties will be returned when all
    // properties have been checked.
    areEqual(value, objectTwo[key])
      ? res : res.concat(key)
  ), []);

// Compare two objects and determine if properties have changed. If so, return true.
const hasChanges = (objectOne, objectTwo) => reduce(objectOne,
  (res, value, key) => (areEqual(value, objectTwo[key])
  ), undefined);

const downloadFile = async (downloadUrl, fileName, saveAs, callbackFn = null) => {
  // Step 1: start the fetch and obtain a reader
  const response = await fetch(downloadUrl);
  const reader = response.body.getReader();

  // Step 2: get total length
  // const contentLength = response.headers.get('Content-Length');

  // Step 3: read the data
  // let receivedLength = 0; // received that many bytes at the moment
  const chunks = []; // array of received binary chunks (comprises the body)
  let downloadHasNotCompleted = true;
  let downloadHasCompleted = false;

  while (downloadHasNotCompleted) {
    const { done, value } = await reader.read();

    if (done) {
      downloadHasNotCompleted = false;
      downloadHasCompleted = true;
      break;
    }

    chunks.push(value);

    // receivedLength += value.length;
    // console.log(`Received ${receivedLength} of ${contentLength}`)
  }

  if (downloadHasCompleted) {
    // Step 4: create a Blob from all chunks
    const blob = new Blob(chunks);

    if (isDefinedAndNotNullAndNotEmpty(blob)) {
      // save the file to the device
      saveAs(blob, fileName);

      if (isDefinedAndNotNullAndNotEmpty(callbackFn)) {
        callbackFn();
      }
    }
  }
};
// Retrieves the parameter names and values from a Url address
// and adds each parameter and it's value to the object, vars.
const getQueryStringParamsFromUrl = (url) => {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });

  return vars;
};

const toQueryString = (paramsObject) => Object
  .keys(paramsObject)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
  .join('&');

const buildThunkHelperActionObject = (typeInput, payload) => {
  let action = {
    payload,
  };
  // Check if the typeInput is a String type.
  // If false, we check if typeInput is an Object that was created by an Object constructor
  // or prototype.
  if (isString(typeInput)) {
    action = {
      ...action,
      type: typeInput,
    };
  } else if (isPlainObject(typeInput)) {
    const { type, payload } = typeInput;
    action = {
      ...action,
      thunkPayload: payload,
      type,
    };
  }

  return action;
};
// Convert a UTC Date type to a local Date type.
const convertUtcDateTimeToLocalDateTime = (utcDateTime) => {
  // Check if utcDateTime is a date
  if (!isDate(utcDateTime)) {
    return null;
  }

  // Convert the UTC Date to a local Date
  return moment(utcDateTime).local();
};
// Format a Local Date type into a string based on the formatTemplate provided.
// A default template is declared. A cultureCode must be provided to define Date's locale.
const formatLocalDateTime = (localDate,
  cultureCode,
  formatTemplate = LOCAL_DATE_TIME_FORMATS.LOCAL_DATE_TIME) => {
  // Check if localDate is not a Date type or cultureCode is not null, undefined, or
  // an empty string. If true, the method returns null.
  if (!isDate(localDate) || !isDefinedAndNotNullAndNotEmpty(cultureCode)) {
    return null;
  }
  // Convert the Date to a local Date, applies a cultureCode to modify the locale,
  // and, finally, format Date based on the specified string
  return moment(localDate).locale(cultureCode).format(formatTemplate);
};
// Check the provided url is a valid
const isValidUrl = (url) => {
  try {
    // Check if url is not null, undefined, or an empty string.
    // If check returns false, method returns false.
    if (isDefinedAndNotNullAndNotEmpty(url)) {
      // Check if the url is a String type.
      if (isString(url)) {
        return Boolean(new URL(url));
      }
    }
  } catch (e) {
    return false;
  }

  return false;
};

const replaceAllOccurrencesInText = (text, match, replace) => {
  if (isDefinedAndNotNullAndNotEmpty(text)) {
    return text.replace(new RegExp(match, 'g'), () => replace);
  }
  return '';
};

export {
  areEqual,
  buildThunkHelperActionObject,
  convertUtcDateTimeToLocalDateTime,
  downloadFile,
  formatLocalDateTime,
  formatPendoVistorId,
  getChangedProperties,
  getQueryStringParamsFromUrl,
  hasChanges,
  isDefinedAndNotNullAndNotEmpty,
  isPropertyDefinedAndNotNullAndNotEmpty,
  isValidNumber,
  isValidNumberGreaterThanZero,
  isValidUrl,
  toQueryString,
  getEnvironmentShortcode,
  replaceAllOccurrencesInText,
};
