import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, Button, ButtonProps,
} from '@lake-superior/ui-core';
import { faExclamationTriangle, faRedo } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';

class MFECatch extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;

    this.state = {
      hasError: false,
      buttonTranslationId: 'mfe.catch.retryingIn',
      retryCount: props.retryCount,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
    });

    const { onError, name } = this.props;
    onError(name.toLowerCase(), error, errorInfo);
    this.triggerRetryMechanism();
  }

  setButtonTextCurrentRetryCountAndRestartCounters = () => {
    const { retryCount } = this.props;
    this.setState({
      retryCount,
      buttonTranslationId: 'mfe.catch.retryingIn',
    });
  }

  restarCounters() {
    const { retryCount } = this.props;
    this.setState({
      retryCount,
    });
  }

  triggerRetryMechanism() {
    this.setButtonTextCurrentRetryCountAndRestartCounters();

    this.interval = setInterval(() => {
      const { retryCount } = this.state;

      this.setState({
        retryCount: retryCount - 1,
      });

      if ((retryCount - 1) <= 0) {
        this.setState({
          buttonTranslationId: 'mfe.catch.retrying',
        });
        clearInterval(this.interval);

        setTimeout(() => {
          const { onRetry } = this.props;
          onRetry();

          this.setState({
            hasError: false,
          });
        }, 500);
      }
    }, 1000);
  }

  render() {
    const { hasError, buttonTranslationId, retryCount } = this.state;
    const { children, onRetry } = this.props;

    if (hasError) {
      return (
        <div className="mfe-catch-wrapper">
          <div>
            <Icon className="mfe-catch-warning-icon" icon={faExclamationTriangle} />
          </div>
          <div>
            <h4>
              <Translate id="mfe.catch.title" />
            </h4>
          </div>
          <div>
            <small>
              <Translate id="mfe.catch.description" />
            </small>
          </div>
          <div>
            <Button
              translateId={buttonTranslationId}
              translateData={{
                retryCount,
              }}
              size={ButtonProps.ButtonSizes.MEDIUM}
              style={ButtonProps.ButtonSizes.PR}
              onClick={onRetry}
              icon={faRedo}
            />
          </div>
        </div>
      );
    }

    return children;
  }
}

MFECatch.propTypes = {
  children: PropTypes.element.isRequired,
  onError: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  retryCount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default MFECatch;
