import React from 'react';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import WithMFECatch from './withMFE.Catch';

class MFEBridge extends React.Component {
  componentDidMount() {
    const { onComponentDidMount } = this.props;

    if (isFunction(onComponentDidMount)) {
      onComponentDidMount();
    }
  }

  componentWillUnmount() {
    const { onComponentWillUnmount } = this.props;

    if (isFunction(onComponentWillUnmount)) {
      onComponentWillUnmount();
    }
  }

  render() {
    const { children } = this.props;
    return (
      <WithMFECatch>
        {
          React.cloneElement(children, { ...this.props })
        }
      </WithMFECatch>
    );
  }
}

MFEBridge.propTypes = {
  onComponentDidMount: PropTypes.func,
  onComponentWillUnmount: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

MFEBridge.defaultProps = {
  onComponentDidMount: null,
  onComponentWillUnmount: null,
};

export default MFEBridge;
