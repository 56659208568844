import './src/styles/MFE.scss';
import Context from './src/MFEContext';
import enLocale from './src/locales/translations/en.translations.json';
import esLocale from './src/locales/translations/es.translations.json';
import MFE from './src/MFE';
import MFEManager, { registerWindowDependency } from './src/MFE.Manager';

const MFEManagerConsumer = Context.Consumer;

export default MFE;

export {
  enLocale,
  esLocale,
  MFEManager,
  MFEManagerConsumer,
  registerWindowDependency,
};
