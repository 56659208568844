const ADDRESS_TYPE = Object.freeze({
  ASSOCIATE: 900,
  BRANCH: 901,
  CONTACT: 902,
  CUSTOMER: 903,
  WORKSITE: 904,
});

const PR_BUSINESS_UNIT_ID = 1;

const DATE_FORMATS = {
  DATE: 'YYYY-MM-DD',
};

const DEVICE_TYPES = Object.freeze({
  PR_MOBILE: 1,
  PR_WEB: 2,
});

const FAULTS = {
  FAULT_CODE_PREFIX: 'FAULT_CODE_',
  UNHANDLED_EXCEPTION_CODE: 500,
};

const INVALID_THUNK_BASE_URL = 'INVALID_THUNK_BASE_URL';

const INVALID_THUNK_PARAMETERS = 'INVALID_THUNK_PARAMETERS';

const LOCAL_DATE_TIME_FORMATS = {
  LOCAL_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  LOCAL_TIME: 'LTS',
  LOCAL_DATE: 'L',
};

const NOTIFICATION_STATE = Object.freeze({
  ERROR: 921,
  INFORMATION: 922,
  SUCCESS: 923,
  WARNING: 924,
});

const NOTIFICATION_STATUS = Object.freeze({
  READ: 918,
  NOT_READ: 919,
  BOTH: 920,
});

const NOTIFICATION_TARGET_TYPE = Object.freeze({
  ASSOCIATE: 913,
  BRANCH: 914,
  CUSTOMER: 915,
});

const NOTIFICATION_TYPE = Object.freeze({
  STANDARD: 916,
  CUSTOM: 917,
});

const PREFERRED_CONTACT_METHODS = Object.freeze({
  EMAIL: 1,
  PHONENUMBER: 2,
});

const PROFILE_TARGET_TYPE = Object.freeze({
  ASSOCIATE: 955,
  BRANCH: 956,
  BRANCH_USER: 957,
  CUSTOMER: 958,
  CUSTOMER_USER: 959,
});

const REG_EX_EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*)?[a-z0-9]$/i;
const REG_EX_NUMBERS_ONLY = /^\d+$/;
const REG_EX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
const REG_EX_POSTAL_CODE = /^([a-z0-9]{5,9})$/i;

const SESSION_TYPES = {
  ASSOCIATE: 1,
  BRANCH: 2,
  CUSTOMER: 3,
};

const STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
  REMOVED: 3,
};

const TASK_PRIORITY = Object.freeze({
  HIGH: 935,
  NORMAL: 936,
  LOW: 937,
});

const TASK_MGN_STATUS = Object.freeze({
  INCOMPLETE: 930,
  COMPLETED: 931,
  ALL: 932,
  IN_PROGRESS: 2699,
  TO_DO: 2700,
  CLOSED: 2701,
});

const TASK_TARGET_TYPE = Object.freeze({
  ASSOCIATE: 925,
  BRANCH: 926,
  BRANCH_USER: 927,
  CUSTOMER: 928,
  CUSTOMER_USER: 929,
});

const TASK_TYPE = Object.freeze({
  STANDARD: 933,
  CUSTOM: 934,
});

const THUNK_HELPER_ACTIONS = {
  ERROR: 'exceptionManagerEvents/Error',
};

const ACCESS_LEVEL = Object.freeze({
  FORBIDDEN: 1,
  READONLY: 2,
  READWRITE: 3,
});

const MODULE_TYPES = Object.freeze(
  {
    COMPONENT: 1,
    ROUTE: 2,
    SERVICE_ENDPOINT: 3,
  },
);

export {
  ACCESS_LEVEL,
  ADDRESS_TYPE,
  PR_BUSINESS_UNIT_ID,
  DATE_FORMATS,
  DEVICE_TYPES,
  FAULTS,
  INVALID_THUNK_BASE_URL,
  INVALID_THUNK_PARAMETERS,
  LOCAL_DATE_TIME_FORMATS,
  MODULE_TYPES,
  NOTIFICATION_STATE,
  NOTIFICATION_STATUS,
  NOTIFICATION_TARGET_TYPE,
  NOTIFICATION_TYPE,
  PREFERRED_CONTACT_METHODS,
  PROFILE_TARGET_TYPE,
  REG_EX_EMAIL,
  REG_EX_NUMBERS_ONLY,
  REG_EX_PASSWORD,
  REG_EX_POSTAL_CODE,
  SESSION_TYPES,
  STATUS,
  TASK_PRIORITY,
  TASK_MGN_STATUS,
  TASK_TARGET_TYPE,
  TASK_TYPE,
  THUNK_HELPER_ACTIONS,
};
