import React, {
  lazy, Suspense, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import { isNumber, isFunction, isArray } from 'lodash';
import nextId from 'react-id-generator';
import { Spinner, MFERoute } from '@lake-superior/ui-core';
import MFECatch from './MFE.Catch';
import Context from './MFEContext';
import MFEWrapper from './MFE.Wrapper';

const handleOnError = (onError, context) => {
  if (isFunction(onError)) {
    onError();
  }

  context.handleOnMFEFetchingError();
};

const handleOnRetry = (onRetry, setMFEKey) => {
  if (isFunction(onRetry)) {
    onRetry();
  }

  setMFEKey(nextId('mfe-'));
};

const buildPaths = (name, paths) => {
  const defaultPath = `/${name}`;

  if (isArray(paths)) {
    paths.push(defaultPath);
    return paths;
  }

  return [defaultPath];
};

const MFE = ({
  name, loading, retryCount, minimumLoadingTime,
  onError, onRetry, paths, showOnRoute, ...props
}) => {
  const [MFEKey, setMFEKey] = useState(() => nextId('mfe-'));

  return (
    <Context.Consumer>
      {
            (context) => {
              const MFEComponent = lazy(() => context.loaderPromise(
                name,
                (isNumber(minimumLoadingTime))
                  ? minimumLoadingTime
                  : context.MINIMUM_LOADING_TIME_MS,
              ));

              const suspense = (
                <Suspense fallback={(loading) || <Spinner />}>
                  <section className={`MFE-Container MFE-${name} `}>
                    <MFEWrapper>
                      <MFEComponent
                        key={MFEKey}
                        {...props}
                      />
                    </MFEWrapper>
                  </section>
                </Suspense>
              );

              const renderMFE = ((showOnRoute)
                ? (
                  <MFERoute
                    name={name}
                    auth={context.auth}
                    paths={buildPaths(name, paths)}
                    render={() => suspense}
                  />
                )
                : suspense);

              return (
                <MFECatch
                  retryCount={(isNumber(retryCount)) ? retryCount : context.DEFAULT_RETRY_COUNT}
                  onError={() => handleOnError(onError, context)}
                  onRetry={() => handleOnRetry(onRetry, setMFEKey)}
                  name={name}
                >
                  {renderMFE}
                </MFECatch>
              );
            }
          }
    </Context.Consumer>
  );
};

MFE.propTypes = {
  name: PropTypes.string.isRequired,
  retryCount: PropTypes.number,
  minimumLoadingTime: PropTypes.number,
  loading: PropTypes.element,
  onError: PropTypes.func,
  onRetry: PropTypes.func,
  paths: PropTypes.arrayOf(PropTypes.string),
  showOnRoute: PropTypes.bool,

};

MFE.defaultProps = {
  loading: null,
  retryCount: null,
  minimumLoadingTime: null,
  onError: null,
  onRetry: null,
  paths: null,
  showOnRoute: false,
};

const memoAreEqual = ({ retryCount, minimumLoadingTime },
  { retryCount: rc, minimumLoadingTime: mlt }) => (
  retryCount === rc && minimumLoadingTime === mlt
);

export default memo(MFE, memoAreEqual);
