import { isDefinedAndNotNullAndNotEmpty } from './tools';

// Formats a US phone number
const formatPhoneNumber = (phoneNumber) => {
  // leaves only numbers for regEx comparison
  const cleanedPhoneNumber = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleanedPhoneNumber.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // if match[1] exists, means the cleanedPhoneNumber is a toll free number
    if (match[1]) {
      // a 1-800 number, format: x-xxx-xxx-xxxx
      return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
    }
    // other US phone, format: (xxx) xxx-xxxx
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  // returns null if not a phone number
  return null;
};

const getPhoneRegex = (countryId) => {
  switch (countryId) {
    case 'USA':
    case 'CAN':
      return (/^\+1 \(\d{3}\) \d{3}-\d{4}$/);
    case 'MEX':
      return (/^\+52 \(\d{3}\) \d{3}-\d{4}$/);
    case 'COL':
      return (/^\+57 \d{10}$/);
    case 'PER':
      return (/^\+51 \d{9}$/);
    case 'CRC':
      return (/^\+506 \d{4}-\d{4}$/);
    default:
      return /^$/;
  }
};

// Checks if the phone number input is a formatted phone number:
// based on the 'countryPhoneCode' attribute, checks if the phone number
// is valid against the country phone code regex.
const isValidPhoneNumber = (phoneNumber, countryPhoneCode) => {
  const regEx = getPhoneRegex(countryPhoneCode.countryId);
  return phoneNumber.match(regEx);
};

// Sets a mask by replacing every digit with an X except the last 4 digits.
const getMaskedPhone = (phoneNumber) => {
  if (!isDefinedAndNotNullAndNotEmpty(phoneNumber)) {
    return '#######';
  }
  // Matches US/CA numbers like +17325322025
  if (phoneNumber.match(/\+1\d{3}\d{3}\d{4}/) !== null) {
    return '###-###-'.concat(phoneNumber.substring(phoneNumber.length - 4));
  }
  return `${[...phoneNumber.substring(0, phoneNumber.length - 4)].map(() => '#').join('')}${phoneNumber.substr(-4)}`;
};

export {
  formatPhoneNumber,
  isValidPhoneNumber,
  getMaskedPhone,
};
