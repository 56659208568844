import { THUNK_HELPER_ACTIONS } from '../utilities/constants';

const handleJsError = (dispatch, errorData) => {
  // Dispatch error action for component's integration
  dispatch({
    type: THUNK_HELPER_ACTIONS.ERROR,
    payload: errorData,
  });
};

export default handleJsError;
