import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@lake-superior/ui-core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

class WithMFECatch extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location } = this.props;
    const { hasError } = this.state;
    if (location !== prevLocation && hasError) {
      // using setState is not a good price when it is used without any condition
      // this is a safe way to call it, that's why esLint is being ignored.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.warn('--------------------------------------- MFE UNHANDLED EXCEPTION ---------------------------------------');
    console.error(errorInfo);
    console.error(error);
    console.warn('--------------------------------------- MFE UNHANDLED EXCEPTION ---------------------------------------');
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="with-mfe-catch-wrapper">
          <div>
            <Icon className="mfe-catch-warning-icon" icon={faExclamationTriangle} />
          </div>
          <div>
            <h3>
              <Translate id="mfe.withmfe.title" />
            </h3>
          </div>
          <div className="with-mfe-catch-error">
            <Translate id="mfe.withmfe.description" />
          </div>
        </div>
      );
    }

    return children;
  }
}

WithMFECatch.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(WithMFECatch);
