import Q from 'q';
import { merge, isPlainObject, isBoolean } from 'lodash';
import apiThunkHelper from './api.thunk.helper';
import { isDefinedAndNotNullAndNotEmpty } from '../utilities/tools';
import {
  PR_BUSINESS_UNIT_ID,
  INVALID_THUNK_PARAMETERS,
  INVALID_THUNK_BASE_URL,
  DEVICE_TYPES,
}
from '../utilities/constants';
import { getObjectLocalStorage, getLocalStorage } from '../managers/local.storage';
import { thunkExceptionConfig } from '../ExceptionHandling/api.global.exception';
import appsettings from '../config/appsettings.json';

const getBaseRequestHeaders = () => ({
  Accept: '*/*',
  'Content-Type': 'application/json; charset=utf-8',
  UserAgent: navigator.userAgent,
});

const buildExceptionHandlerConfig = (exceptionHandlerConfig) => {
  let _exceptionHandlerConfig = !isPlainObject(exceptionHandlerConfig)
    ? thunkExceptionConfig({
      useDefaultHandler: true,
    }) : exceptionHandlerConfig;

  if (!isBoolean(_exceptionHandlerConfig.useDefaultHandler)) {
    _exceptionHandlerConfig.useDefaultHandler = true;
  }

  if (isBoolean(exceptionHandlerConfig)) {
    _exceptionHandlerConfig = thunkExceptionConfig({
      useDefaultHandler: exceptionHandlerConfig,
    });
  }

  return _exceptionHandlerConfig;
};

const thunkHelper = (dispatch, types, apiConfig, exceptionHandlerConfig) => {
  const deferred = Q.defer();

  try {
    if (!isDefinedAndNotNullAndNotEmpty(dispatch)
      || !isDefinedAndNotNullAndNotEmpty(types)
      || !isDefinedAndNotNullAndNotEmpty(apiConfig)) {
      throw new Error(INVALID_THUNK_PARAMETERS);
    }

    if (!isDefinedAndNotNullAndNotEmpty(apiConfig.baseURL)) {
      throw new Error(INVALID_THUNK_BASE_URL);
    }

    return apiThunkHelper(
      dispatch,
      types,
      apiConfig,
      buildExceptionHandlerConfig(exceptionHandlerConfig),
    );
  } catch (error) {
    deferred.reject(error.message);
    throw error;
  }
};

const webThunkHelper = (dispatch, types, apiConfig, exceptionHandlerConfig) => {
  const internalUserData = getObjectLocalStorage(appsettings.localStorageKeys.INTERNAL_USER);

  const _apiConfig = merge(apiConfig, {
    headers: {
      ...getBaseRequestHeaders(),
      BusinessUnitId: PR_BUSINESS_UNIT_ID,
      LoggedInUserSessionType: internalUserData ? internalUserData.sessionType : 0,
      DeviceType: DEVICE_TYPES.PR_WEB,
    },
  });

  // requireSessionToken will include the JWT token as part of the http request
  if (appsettings.requireSessionToken) {
    const sessionToken = getLocalStorage(appsettings.localStorageKeys.PR_SESSION_TOKEN);
    const cwaToken = getLocalStorage(appsettings.localStorageKeys.PR_CWA_TOKEN);

    if (isDefinedAndNotNullAndNotEmpty(sessionToken)) {
      _apiConfig.headers.Authorization = sessionToken;
    }

    if (isDefinedAndNotNullAndNotEmpty(cwaToken)) {
      _apiConfig.headers.CwaToken = cwaToken;
    }
  }

  return thunkHelper(dispatch, types, _apiConfig, exceptionHandlerConfig);
};

export const mobileThunkHelper = (
  dispatch, types, apiConfig, exceptionHandlerConfig, sessionToken, sessionType,
) => {
  const _apiConfig = merge(apiConfig, {
    headers: {
      ...getBaseRequestHeaders(),
      BusinessUnitId: PR_BUSINESS_UNIT_ID,
      Authorization: sessionToken,
      LoggedInUserSessionType: sessionType,
      DeviceType: DEVICE_TYPES.PR_MOBILE,
    },
  });

  return thunkHelper(dispatch, types, _apiConfig, exceptionHandlerConfig);
};

export default webThunkHelper;
