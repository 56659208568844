import _ from 'lodash';
import * as moment from 'moment';
import * as ReactDOMServer from 'react-dom/server';
import * as ReactLocalizeRouter from 'react-localize-redux';
import * as ReactRedux from 'react-redux';
import * as ReactRouter from 'react-router';
import * as Redux from 'redux';
import * as ReduxToolKit from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { useEffect, memo } from 'react';
import ReactDOM from 'react-dom';
import * as ReactRouterDOM from 'react-router-dom';
import * as FontawesomeSvgCore from '@fortawesome/fontawesome-svg-core';
import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons';
import * as ReactFontawesome from '@fortawesome/react-fontawesome';
import * as ReactFontawesomeProIcons from '@fortawesome/fontawesome-pro';
import * as ReactFontawesomeProLigtht from '@fortawesome/pro-light-svg-icons';
import * as ReactFontawesomeProRegular from '@fortawesome/pro-regular-svg-icons';
import * as ReactFontawesomeProSolid from '@fortawesome/pro-solid-svg-icons';
import Classnames from 'classnames';
import q from 'q';
import axios from 'axios';
import ramda from 'ramda';
import reselect from 'reselect';
import { localStorageManager, tools } from '@lake-superior/core';
import appsettings from './config/appsettings.json';
import Context from './MFEContext';

const scripCache = new Map();

const applyTranslations = (component, addTranslationForLanguage) => {
  if (_.isFunction(addTranslationForLanguage)) {
    Object.entries(component.translations).forEach(([key, translate]) => {
      addTranslationForLanguage(translate, key.toString());
    });
  }
};

const applyReducers = (component, reducerManager) => {
  if (_.isPlainObject(component.reducers)) {
    Object.entries(component.reducers).forEach(([key, value]) => {
      reducerManager.add(key, value);
    });
  }
};

const configMFE = (component, name, reducerManager, addTranslationForLanguage, dispatch) => {
  const { menuConfig } = component;
  applyTranslations(component, addTranslationForLanguage);
  applyReducers(component, reducerManager);

  dispatch(
    {
      type: 'shell/MFEMounted',
      payload: {
        name,
        menuConfig,
      },
    },
  );
};

const getRequestHeaders = () => {
  const sessionToken = localStorageManager.getLocalStorage(
    appsettings.localStorageKeys.PR_SESSION_TOKEN,
  );

  if (tools.isDefinedAndNotNullAndNotEmpty(sessionToken)) {
    return {
      Identity: sessionToken,
    };
  }

  return {};
};

const createScriptPromise = (url,
  name,
  minimumLoadingTime,
  reducerManager,
  addTranslationForLanguage,
  dispatch) => new Promise((resolve, reject) => {
  try {
    // Load MFE content
    axios.get(url, getRequestHeaders())
      .then((response) => {
        try {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.text = response.data;
          document.body.appendChild(script);

          let component = window[name];
          configMFE(component, name, reducerManager, addTranslationForLanguage, dispatch);

          setTimeout(() => {
            resolve(component);
            // Clean variables and window object
            component = null;
            window[name] = null;
          }, minimumLoadingTime);
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  } catch (error) {
    reject(new Error(error));
  }
});

const buildURLToFetchScript = (BASE_URL, mfeName) => `${BASE_URL}/${mfeName}/index.js`;

const getLoaderPromise = (
  BASE_URL, name, minimumLoadingTime, reducerManager, addTranslationForLanguage, dispatch,
) => new Promise((resolve, reject) => {
  const mfeName = name.toLowerCase();
  try {
    if (scripCache.has(mfeName)) {
      resolve(scripCache.get(mfeName));
    } else {
      const scriptPromise = createScriptPromise(
        buildURLToFetchScript(BASE_URL, mfeName),
        mfeName,
        minimumLoadingTime,
        reducerManager, addTranslationForLanguage, dispatch,
      ).catch(reject);

      scripCache.set(mfeName, scriptPromise);
      resolve(scriptPromise);
    }
  } catch (error) {
    reject();
  }
});

const loadAllMappedMFE = (
  BASE_URL,
  MFEs,
  minimumLoadingTime,
  reducerManager,
  addTranslationForLanguage,
  dispatch,
) => {
  Promise.all(MFEs.map((MFE) => getLoaderPromise(
    BASE_URL, MFE.mfe, minimumLoadingTime, reducerManager, addTranslationForLanguage, dispatch,
  )));
};

const registerWindowDependency = (name, dependency) => {
  if (!window[name]) {
    window[name] = dependency;
  }
};

const loadGlobalWideUsedDependencies = () => {
  registerWindowDependency('axios', axios);
  registerWindowDependency('Classnames', Classnames);
  registerWindowDependency('FontawesomeSvgCore', FontawesomeSvgCore);
  registerWindowDependency('FreeSolidSvgIcons', FreeSolidSvgIcons);
  registerWindowDependency('lodash', _);
  registerWindowDependency('moment', moment);
  registerWindowDependency('PropTypes', PropTypes);
  registerWindowDependency('q', q);
  registerWindowDependency('ramda', ramda);
  registerWindowDependency('React', React);
  registerWindowDependency('ReactDOM', ReactDOM);
  registerWindowDependency('ReactDOMServer', ReactDOMServer);
  registerWindowDependency('ReactFontawesome', ReactFontawesome);
  registerWindowDependency('ReactLocalizeRouter', ReactLocalizeRouter);
  registerWindowDependency('ReactRedux', ReactRedux);
  registerWindowDependency('ReactRouter', ReactRouter);
  registerWindowDependency('ReactRouterDOM', ReactRouterDOM);
  registerWindowDependency('Redux', Redux);
  registerWindowDependency('ReduxToolKit', ReduxToolKit);
  registerWindowDependency('reselect', reselect);
  registerWindowDependency('ReactFontawesomeProIcons', ReactFontawesomeProIcons);
  registerWindowDependency('ReactFontawesomeProLigtht', ReactFontawesomeProLigtht);
  registerWindowDependency('ReactFontawesomeProRegular', ReactFontawesomeProRegular);
  registerWindowDependency('ReactFontawesomeProSolid', ReactFontawesomeProSolid);
};

const handleOnMFEFetchingError = (name, error) => {
  /* Invalidate failed promise, when the MFE lifecycle kicks in,
  a new promise will be created and therefore retry the fetching */
  scripCache.delete(name);
  console.error('MFE ERROR', error);
};

// -- Component -->
const MFEManager = ({
  children, BASE_URL, MFEs, DEFAULT_RETRY_COUNT,
  MINIMUM_LOADING_TIME_MS, reducerManager, addTranslationForLanguage, dispatch,
}) => {
  useEffect(() => {
    loadGlobalWideUsedDependencies();

    if (!MFEs) {
      return;
    }
    if (MFEs.length === 0) {
      return;
    }

    loadAllMappedMFE(BASE_URL, MFEs, MINIMUM_LOADING_TIME_MS,
      reducerManager, addTranslationForLanguage, dispatch);
  }, [
    BASE_URL,
    MFEs,
    MINIMUM_LOADING_TIME_MS,
    reducerManager,
    addTranslationForLanguage,
    dispatch]);

  return (
    <>
      <Context.Provider value={{
        DEFAULT_RETRY_COUNT,
        MINIMUM_LOADING_TIME_MS,
        loaderPromise: (mfeName, minimumLoadingTime) => getLoaderPromise(
          BASE_URL,
          mfeName,
          minimumLoadingTime,
          reducerManager, addTranslationForLanguage, dispatch,
        ),
        handleOnMFEFetchingError,
      }}
      >
        {children}
      </Context.Provider>
    </>
  );
};

MFEManager.propTypes = {
  children: PropTypes.element.isRequired,
  reducerManager: PropTypes.instanceOf(Object).isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  BASE_URL: PropTypes.string,
  DEFAULT_RETRY_COUNT: PropTypes.number,
  MINIMUM_LOADING_TIME_MS: PropTypes.number,
  MFEs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

MFEManager.defaultProps = {
  BASE_URL: null,
  DEFAULT_RETRY_COUNT: null,
  MINIMUM_LOADING_TIME_MS: null,
  MFEs: null,
};

export default memo(ReactLocalizeRouter.withLocalize(MFEManager), ({
  MFEs,
}, { MFEs: newMFEs }) => (MFEs === newMFEs));

export {
  registerWindowDependency,
};
