import { FAULTS } from '../utilities/constants';
import { isDefinedAndNotNullAndNotEmpty, isPropertyDefinedAndNotNullAndNotEmpty } from '../utilities/tools';
import LogTypes from './log.types';

const getCustomFaultCode = (exception) => {
  if (isPropertyDefinedAndNotNullAndNotEmpty(exception.response, 'data.msError')) {
    return exception.response.data.message;
  }
  return null;
};

const getHttpStatusCode = (exception) => ((
  isDefinedAndNotNullAndNotEmpty(exception)
  && isDefinedAndNotNullAndNotEmpty(exception.response))
  ? exception.response.status
  : FAULTS.UNHANDLED_EXCEPTION_CODE);

const determineLoggingEndPoint = (loggingEndpoint, { baseURL }) => {
  if (isDefinedAndNotNullAndNotEmpty(loggingEndpoint)) {
    return loggingEndpoint;
  }

  if (baseURL.endsWith('/')) {
    return `${baseURL}ClientExceptionLogger`;
  }

  return `${baseURL}/ClientExceptionLogger`;
};

const getErrorData = (exception, {
  loggingEndpoint,
  exceptionId,
  logType,
  asAlert,
}, apiConfig) => ({
  faultCode: getCustomFaultCode(exception),
  statusCode: getHttpStatusCode(exception),
  exception: exception.message,
  apiConfig,
  loggingEndpoint: determineLoggingEndPoint(loggingEndpoint, apiConfig),
  exceptionId,
  logType,
  asAlert,
});

export const thunkExceptionConfig = ({
  useDefaultHandler = true,
  asAlert = true,
  loggingEndpoint = null,
  exceptionId = 100000, // Base exception Id, could be any other number
  logType,
}) => ({
  useDefaultHandler,
  asAlert,
  loggingEndpoint,
  exceptionId,
  logType: logType || LogTypes.ERROR,
});

export default getErrorData;
